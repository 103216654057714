import { deleteDocument } from '@sgdocs/client';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { getToken } from '../../common/hooks/getToken';
import { EWidgetsSgmDocs } from '../../common/widget/enum';
import { UploadQueueItem } from '../reducer/UploadQueueReducer';
import Filename from './Filename';

interface FailedStateProps {
  fileWithState: UploadQueueItem;
  sgdocsBaseUrl: string;
  isPartialyCompleted: boolean;
  enableDeletion: boolean;
  errorDetail?: string;
  clearFromQueue: () => void;
  retryFile: () => void;
  continueFileUpload: () => void;
  resetFileUpload: () => void;
}

export const FailedState: React.FC<FailedStateProps> = ({
  fileWithState,
  sgdocsBaseUrl,
  isPartialyCompleted,
  enableDeletion = false,
  errorDetail = 'Failed',
  clearFromQueue,
  retryFile,
  continueFileUpload,
  resetFileUpload,
}: FailedStateProps): JSX.Element => {
  const onDelete = (): void => {
    const tokenUsed = getToken(null);
    deleteDocument({
      workspaceId: fileWithState.workspaceId,
      documentId: fileWithState.documentId,
      authorizationHeader: tokenUsed,
      baseUrl: sgdocsBaseUrl,
      component: EWidgetsSgmDocs.CREATE_DOCUMENT_FORM,
    });
    clearFromQueue();
  };

  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center justify-content-between text-danger">
      <svg className="col-1 mx-3" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-418 -296)">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(419 297)"
          ></path>
          <g transform="translate(429 310)">
            <path fill="none" d="M0,0H20V20H0Z"></path>
            <circle fill="currentColor" cx="12" cy="19" r="2" transform="translate(-1.667 0.667)" />
            <path fill="currentColor" d="M10 3h4v12h-4z" transform="translate(-1.667 0.667)" />
          </g>
        </g>
      </svg>
      <Filename filename={fileWithState.file.name} additionalCss="col-2 me-3" />

      {isPartialyCompleted && (
        <span className="col text-center me-3">
          This document has already a content of same mimetype partially uploaded. <br />
          <button className="btn btn-secondary mt-1 mx-4" onClick={continueFileUpload} aria-label="continue">
            Continue the previous upload
          </button>
          <button className="btn btn-secondary mt-1 mx-4" onClick={resetFileUpload} aria-label="reset" id="resetBtn">
            Reset the upload
          </button>
          <UncontrolledTooltip placement="top" target="resetBtn">
            Pick this option if you are uploading a different content than the previous one
          </UncontrolledTooltip>
        </span>
      )}
      {!isPartialyCompleted && (
        <>
          <span className="col text-center me-3">{errorDetail}</span>
          <button className="col-1 btn btn-md btn-flat-danger" onClick={retryFile} id="retryFileBtn" aria-label="retry">
            <span className="icon">replay</span>
          </button>
          <UncontrolledTooltip placement="top" target="retryFileBtn">
            Retry to upload this file.
          </UncontrolledTooltip>
        </>
      )}
      {enableDeletion ? (
        <>
          <button
            className="col-1 btn btn-md btn-flat-primary me-3"
            onClick={onDelete}
            id="deleteBtn"
            aria-label="clear"
          >
            <span className="icon">delete</span>
          </button>
          <UncontrolledTooltip placement="top" target="deleteBtn">
            Delete the document created for this file.
          </UncontrolledTooltip>
        </>
      ) : (
        <button className="col-1 btn btn-md btn-flat-light me-3" onClick={clearFromQueue} aria-label="clear">
          <span className="icon">close</span>
        </button>
      )}
    </div>
  );
};
