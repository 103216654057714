/**
 * Returns the token that should be used in hooks
 *
 * either using a token provided by the caller or getting it from the bus.
 */
export function getToken(tokenFromProps: string | null): string {
  return tokenFromProps
    ? tokenFromProps
    : window.SGWTWidgetConfiguration.bus.dangerouslyGetCurrentValue('sg-connect.access-token') || '';
}
