import React, { ReactElement, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

interface FilenameProps {
  filename: string;
  additionalCss?: string;
}

const Filename: React.FC<FilenameProps> = ({ filename, additionalCss = '' }): ReactElement => {
  const filenameSpan = useRef<HTMLSpanElement>(null);
  return (
    <span>
      <span className={`fw-medium text-wrap text-truncate ${additionalCss}`} ref={filenameSpan}>
        {filename}
      </span>
      <UncontrolledTooltip placement="top" target={filenameSpan} fade={false}>
        {filename}
      </UncontrolledTooltip>
    </span>
  );
};

export default Filename;
