import React, { useCallback, useEffect } from 'react';
import { QueuedState } from './QueuedState';
import { DocumentsFilesWithState, UploadState } from '../SGMDocsUpload';
import { UploadingState } from './UploadingState';
import { FailedState } from './FailedState';
import { UploadedState } from './UploadedState';
import { useUploadQueue } from '../hooks/UseUploadQueue';
import { Content } from '@sgdocs/client';

interface InternalSGMDocsUploadProps extends UploadState {
  sgdocsBaseUrl: string;
  fileChunkMinLength: number;
  enableDeletion?: boolean;
  onResetFileUploadFailed?: (fileId: string, reason: any) => void;
  onUploadCompleted?: (newContentCreated: Content) => void;
  onUploadFailed?: (failureFile: { documentId: string; workspaceId: string; reason: any; file: File }) => void;
  onDocumentsFilesChange: (documentsFiles: DocumentsFilesWithState) => void;
}

export const InternalSGMDocsUpload: React.FC<InternalSGMDocsUploadProps> = ({
  sgdocsBaseUrl,
  fileChunkMinLength,
  enableDeletion = false,
  documentsFiles,
  documentsFilesTracker,
  onResetFileUploadFailed,
  onDocumentsFilesChange,
  onUploadCompleted,
  onUploadFailed,
}: InternalSGMDocsUploadProps): JSX.Element => {
  const onFilesEnqueued = useCallback(() => {
    onDocumentsFilesChange({});
  }, []);

  const { uploadQueueState, enqueueFilesToUpload, clearFileFromQueue, retryFile, resetFileUpload, continueFileUpload } =
    useUploadQueue(
      sgdocsBaseUrl,
      fileChunkMinLength,
      () =>
        new Promise((resolve) =>
          resolve(window.SGWTWidgetConfiguration.bus.dangerouslyGetCurrentValue('sg-connect.access-token') || '')
        ),
      onResetFileUploadFailed,
      onFilesEnqueued,
      onUploadCompleted,
      onUploadFailed
    );

  useEffect(() => {
    if (documentsFiles) {
      enqueueFilesToUpload(documentsFiles);
    }
  }, [documentsFilesTracker]);

  return (
    <>
      {uploadQueueState.map((fileWithState) => {
        switch (fileWithState.state) {
          case 'QUEUED':
            return (
              <QueuedState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                clearFromQueue={(): void => clearFileFromQueue(fileWithState.id)}
              />
            );
          case 'UPLOADING':
            return (
              <UploadingState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                clearFromQueue={(): void => clearFileFromQueue(fileWithState.id)}
                uploadingProgress={fileWithState.uploadingProgress || 0}
              />
            );
          case 'FAILED':
            return (
              <FailedState
                key={fileWithState.id}
                sgdocsBaseUrl={sgdocsBaseUrl}
                fileWithState={fileWithState}
                enableDeletion={enableDeletion}
                clearFromQueue={(): void => clearFileFromQueue(fileWithState.id)}
                retryFile={(): void => retryFile(fileWithState.id)}
                continueFileUpload={(): void => continueFileUpload(fileWithState.id)}
                resetFileUpload={(): void => resetFileUpload(fileWithState)}
                errorDetail={fileWithState.errorDetail}
                isPartialyCompleted={
                  !!fileWithState.alreadyUploadedChunks && fileWithState.alreadyUploadedChunks.length > 0
                }
              />
            );
          case 'UPLOADED':
            return (
              <UploadedState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                sizeInBytes={fileWithState.file.size}
                clearFromQueue={(): void => clearFileFromQueue(fileWithState.id)}
              />
            );
        }
      })}
    </>
  );
};
