import React from 'react';
import Filename from './Filename';

export const QueuedState = ({ fileName, clearFromQueue }: { fileName: string; clearFromQueue: () => void }) => {
  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center">
      <svg className="px-4 text-info" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-418 -296)">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(419 297)"
          ></path>
          <g transform="translate(429 310)">
            <path fill="none" d="M0,0H20V20H0Z"></path>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
              transform="translate(-1.667 0.667)"
            />
          </g>
        </g>
      </svg>
      <div className="ps-3 flex-grow-1 text-info" style={{ maxWidth: 'calc(100% - 180px)' }}>
        <div className="d-flex justify-content-between align-items-center">
          <Filename filename={fileName} />
          <span className="text-end text-secondary">Pending</span>
        </div>
      </div>
      <div>
        <button className="btn btn-lg btn-flat-light mx-4" onClick={clearFromQueue} aria-label="clear">
          <i className="icon">close</i>
        </button>
      </div>
    </div>
  );
};
