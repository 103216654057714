import { Content } from '@sgdocs/client';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../common/hooks/queryClient';
import { InternalSGMDocsUpload } from './components/InternalSGMDocsUpload';

export type FileWithRight = {
  file: File;
  rightId?: string;
};

export type DocumentsFiles = {
  [workspaceId: string]: {
    [documentId: string]: FileWithRight[] | File[];
  };
};

export type FileWithState = {
  id: string;
  file: File;
  state: UploadingStateType;
  uploadingProgress?: number;
  rightId?: string;
  errorDetail?: string;
};

export type DocumentsFilesWithState = {
  [workspaceId: string]: {
    [documentId: string]: FileWithState[];
  };
};

export type UploadingStateType = 'UPLOADING' | 'UPLOADED' | 'FAILED' | 'QUEUED';

export type UploadState = {
  documentsFilesTracker: boolean;
  documentsFiles?: DocumentsFilesWithState;
};

export function generateFileId(file: File, documentId: string) {
  return `${file.name}-${documentId}-${Math.random()}`;
}

export function updateFileStateToQueued(documentId: string, file: File | FileWithRight): FileWithState {
  return file instanceof File
    ? {
        id: generateFileId(file, documentId),
        state: 'QUEUED',
        file: file,
      }
    : {
        id: generateFileId(file.file, documentId),
        file: file.file,
        state: 'QUEUED',
        rightId: file.rightId,
      };
}

export const mergeDocumentsFiles = (
  leftDocumentFiles: DocumentsFilesWithState,
  rightDocumentFiles: DocumentsFiles
): DocumentsFilesWithState => {
  const workspaceIdsInRight = Object.keys(rightDocumentFiles);

  return {
    ...leftDocumentFiles,
    ...Object.fromEntries(
      workspaceIdsInRight.map((workspaceId) => {
        const documentsIdsInLeft = Object.keys(leftDocumentFiles[workspaceId] || {});
        const documentFilesEntriesInRight = Object.entries(rightDocumentFiles[workspaceId] || {});
        const entriesToPush: [string, FileWithState[]][] = documentFilesEntriesInRight
          .filter(([documentId]) => !documentsIdsInLeft.includes(documentId))
          .map(([documentId, files]) => {
            return [documentId, files.map((fileWithRight) => updateFileStateToQueued(documentId, fileWithRight))];
          });

        const mergedEntries: [string, FileWithState[]][] = documentFilesEntriesInRight
          .filter(([documentId]) => documentsIdsInLeft.includes(documentId))
          .map(([documentId, files]) => {
            return [
              documentId,
              [
                ...leftDocumentFiles[workspaceId][documentId],
                ...files.map<FileWithState>((fileWithRight) => updateFileStateToQueued(documentId, fileWithRight)),
              ],
            ];
          });

        return [
          workspaceId,
          {
            ...(leftDocumentFiles[workspaceId] || {}),
            ...Object.fromEntries([...mergedEntries, ...entriesToPush]),
          },
        ];
      })
    ),
  };
};

const KB = 1024;
const DEFAULT_FILE_CHUNK_MIN_SIZE = KB * KB;

export class SGMDocsUpload extends React.Component<
  {
    sgdocsBaseUrl: string;
    fileChunkMinLength?: number;
    enableDeletion?: boolean;
    onDelete?: () => void;
    onResetFileUploadFailed?: (fileId: string, reason: any) => void;
    onUploadCompleted?: (newContentCreated: Content) => void;
    onUploadFailed?: (failureFile: { documentId: string; workspaceId: string; reason: any; file: File }) => void;
  },
  UploadState
> {
  state: UploadState = { documentsFilesTracker: false };

  upload(documentsFiles: DocumentsFiles): void {
    this.setState((state) => ({
      documentsFilesTracker: !state.documentsFilesTracker,
      documentsFiles: mergeDocumentsFiles(state.documentsFiles || {}, documentsFiles),
    }));
  }

  render(): JSX.Element {
    const {
      onUploadCompleted,
      onUploadFailed,
      onResetFileUploadFailed,
      sgdocsBaseUrl,
      fileChunkMinLength,
      enableDeletion,
    } = this.props;
    return (
      <QueryClientProvider client={queryClient}>
        <InternalSGMDocsUpload
          onDocumentsFilesChange={(documentsFiles): void => this.setState({ documentsFiles })}
          onUploadCompleted={onUploadCompleted}
          onUploadFailed={onUploadFailed}
          onResetFileUploadFailed={onResetFileUploadFailed}
          enableDeletion={enableDeletion}
          sgdocsBaseUrl={sgdocsBaseUrl}
          fileChunkMinLength={
            fileChunkMinLength === undefined || fileChunkMinLength === null
              ? DEFAULT_FILE_CHUNK_MIN_SIZE
              : fileChunkMinLength * DEFAULT_FILE_CHUNK_MIN_SIZE
          }
          documentsFilesTracker={this.state.documentsFilesTracker}
          documentsFiles={this.state.documentsFiles}
        />
      </QueryClientProvider>
    );
  }
}
